@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-gradient {
  background: linear-gradient(
    to right,
    #ff0000,
    #ff7f00,
    #ffff00,
    #00ff00,
    #0000ff,
    #4b0082,
    #9400d3
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-home {
  background: #011136;
}

.text-default {
  color: #011136;
}

.bg-auth {
  background: #1e1e1e;
}

.bg-auth-form {
  background: #000c25;
}

.radius-home {
  border-bottom-right-radius: 15%;
}

.no-scroll {
  overflow: hidden;
}

/* Animation for sliding to the next page */
.slide-next-enter {
  opacity: 0;
  transform: translateX(100%);
}
.slide-next-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}
.slide-next-exit {
  opacity: 1;
  transform: translateX(0);
}
.slide-next-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 500ms, transform 500ms;
}

/* Animation for sliding to the previous page */
.slide-prev-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.slide-prev-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}
.slide-prev-exit {
  opacity: 1;
  transform: translateX(0);
}
.slide-prev-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 500ms, transform 500ms;
}

/* animations.css */

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slide-in-right {
  animation: slideInRight 1s ease-out forwards;
}

.animate-slide-in-left {
  animation: slideInLeft 1s ease-out forwards;
}

/* Media Queries to disable animations on mobile */
@media (max-width: 768px) {
  .animate-slide-in-right,
  .animate-slide-in-left {
    animation: none;
  }
}

@keyframes borderMove {
  0% {
    border-image-slice: 1;
    border-image-source: linear-gradient(90deg, white 0%, blue 50%, white 100%);
  }
  100% {
    border-image-slice: 1;
    border-image-source: linear-gradient(90deg, blue 0%, white 50%, blue 100%);
  }
}

.animate-border-move {
  animation: borderMove 3s linear infinite;
}
